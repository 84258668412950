// extracted by mini-css-extract-plugin
export var takeOver__close = "takeOverKit2025-module--takeOver__close--+qcIY";
export var takeOver__columnCta = "takeOverKit2025-module--takeOver__columnCta--SbZDo";
export var takeOver__columnJerseyCta = "takeOverKit2025-module--takeOver__columnJerseyCta--B4B4+";
export var takeOver__columnTextCta = "takeOverKit2025-module--takeOver__columnTextCta--SiltE";
export var takeOver__columnVideoClicker = "takeOverKit2025-module--takeOver__columnVideoClicker--d-lbx";
export var takeOver__container = "takeOverKit2025-module--takeOver__container--Ti1aN";
export var takeOver__content = "takeOverKit2025-module--takeOver__content--DFUY5";
export var takeOver__ctaCollection = "takeOverKit2025-module--takeOver__ctaCollection--HKXBb";
export var takeOver__left = "takeOverKit2025-module--takeOver__left--b7O1T";
export var takeOver__offsetContainer = "takeOverKit2025-module--takeOver__offsetContainer--ewtCA";
export var takeOver__offsetImage = "takeOverKit2025-module--takeOver__offsetImage--UB1hu";
export var takeOver__right = "takeOverKit2025-module--takeOver__right--C3hWF";
export var takeOver__rightContent = "takeOverKit2025-module--takeOver__rightContent--zAuJ8";
export var takeOver__showDesktop = "takeOverKit2025-module--takeOver__showDesktop--aNVcU";
export var takeOver__showMobile = "takeOverKit2025-module--takeOver__showMobile--omZub";
export var takeOver__wrapper = "takeOverKit2025-module--takeOver__wrapper--hwO97";
export var takeoverNoScroll = "takeOverKit2025-module--takeoverNoScroll--KAKs2";